import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import DropdownFunnel from "../../../Dropdown/DropdownFunnel";
import { truncateDecimals } from "../../../../utils/Helper/reusableFunctions";
import { CurrencyList } from "../../../../constants/currencylist";
import { nFormatter } from "../../../../utils/Helper/reusableFunctions";
import ReactTooltip from "react-tooltip";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet } from "../../../../redux";

class PieGraphDashboard extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    downloadLoader: false,
    chartOptions: {
      credits: {
        enabled: false,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: null,
      tooltip: {
        pointFormat:
          "{series.name}: {point.y} <br /> <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            distance: -50,
            formatter: function () {
              return this.percentage > 10 ? this.point.y : "";
            },
          },
          showInLegend: true,
        },
      },
      series: [
        {
          name: this.props.type,
          colorByPoint: true,
          data: this.props.data?.map((d) => {
            let name = d.source;
            name =
              this.props.segmentBy === "source"
                ? d.source
                : this.props.segmentBy === "account_owner"
                ? d.account_owner
                : d.status;
            return { name, y: d.count };
          }),
        },
      ],
    },
    hoverData: null,
    dataFormat: this.props.dataFormat ? this.props.dataFormat : "count",
  };

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };

  componentDidMount() {
    const segmentBy = this.props.segmentBy;
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    const dataFormat = this.props.dataFormat;
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        tooltip: {
          formatter: function () {
            return segmentBy === "revenue_by_stage"
              ? this.key +
                  ":" +
                  " <b>" +
                  currencySymbol.symbol +
                  "</b>" +
                  this.point.y +
                  "<br /> <b>" +
                  truncateDecimals(this.point.percentage, 1) +
                  "%</b>" +
                  "<br />" +
                  this.series.name
              : this.key +
                  ":" +
                  this.point.y +
                  "<br /> <b>" +
                  truncateDecimals(this.point.percentage, 1) +
                  "%</b>" +
                  "<br />" +
                  this.series.name;
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: -50,
              formatter: function () {
                const formattedCurrency = nFormatter(this.point.y, 1);
                return this.percentage < 10
                  ? ""
                  : dataFormat === "percentage"
                  ? truncateDecimals(this.point.percentage, 1) + "%"
                  : segmentBy === "revenue_by_stage"
                  ? currencySymbol.symbol +
                    formattedCurrency.toLocaleString("en-US")
                  : this.point.y;
              },
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: this.props.type,
            colorByPoint: true,
            data: this.props.data?.map((d) => {
              let name = d.source;
              name =
                this.props.segmentBy === "source"
                  ? d.source
                  : this.props.segmentBy === "account_owner"
                  ? d.account_owner
                  : this.props.segmentBy === "contact_owner"
                  ? d.contact_owner
                  : this.props.segmentBy === "carrier_owner"
                  ? d.carrier_owner
                  : this.props.segmentBy === "opportunity_owner" ||
                    this.props.segmentBy === "revenue_by_owner"
                  ? d.opportunity_owner
                  : this.props.segmentBy === "stage"
                  ? d.stage
                  : this.props.segmentBy === "close_date"
                  ? d.close_date
                  : this.props.segmentBy === "opportunity_owner"
                  ? d.opportunity_owner
                  : this.props.segmentBy === "revenue_by_stage"
                  ? d.revenue_by_stage
                  : this.props.segmentBy === "created_date"
                  ? d.created_date
                  : this.props.segmentBy === "type"
                  ? d.type
                  : this.props.segmentBy === "activity_owner"
                  ? d.activity_owner
                  : this.props.segmentBy === "result"
                  ? d.result
                  : this.props.segmentBy === "status"
                  ? d.status
                  : d.cf_value ||
                    d.bar_name ||
                    d.origin_state ||
                    d.destination_state ||
                    d.account_name ||
                    d.carrier_name ||
                    d.name;
              return {
                name:
                  name === null
                    ? Number.isInteger(parseInt(this.props.segmentBy))
                      ? `No value`
                      : `No ${this.props.segmentBy}`
                    : name,
                y:
                  this.props.segmentBy === "revenue_by_stage" ||
                  this.props.segmentBy === "revenue_by_owner"
                    ? d.revenue
                    : this.props.carrierPieChart
                    ? d.no_of_carrier
                    : d.count,
              };
            }),
          },
        ],
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    this.chart.setSize(this.props.reportWidth);
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: [
            {
              name: this.props.type,
              colorByPoint: true,
              data: this.props.data?.map((d) => {
                let name = d.source;
                name =
                  this.props.segmentBy === "source"
                    ? d.source
                    : this.props.segmentBy === "account_owner"
                    ? d.account_owner
                    : this.props.segmentBy === "contact_owner"
                    ? d.contact_owner
                    : this.props.segmentBy === "carrier_owner"
                    ? d.carrier_owner
                    : this.props.segmentBy === "opportunity_owner" ||
                      this.props.segmentBy === "revenue_by_owner"
                    ? d.opportunity_owner
                    : this.props.segmentBy === "stage"
                    ? d.stage
                    : this.props.segmentBy === "close_date"
                    ? d.close_date
                    : this.props.segmentBy === "opportunity_owner"
                    ? d.opportunity_owner
                    : this.props.segmentBy === "revenue_by_stage"
                    ? d.revenue_by_stage
                    : this.props.segmentBy === "created_date"
                    ? d.created_date
                    : this.props.segmentBy === "type"
                    ? d.type
                    : this.props.segmentBy === "category"
                    ? d.name
                    : this.props.segmentBy === "activity_owner"
                    ? d.activity_owner
                    : this.props.segmentBy === "result"
                    ? d.result
                    : this.props.segmentBy === "status"
                    ? d.status
                    : d.cf_value ||
                      d.bar_name ||
                      d.origin_state ||
                      d.destination_state ||
                      d.account_name ||
                      d.carrier_name ||
                      d.name;
                return {
                  name:
                    name === null
                      ? Number.isInteger(parseInt(this.props.segmentBy))
                        ? `No value`
                        : `No ${this.props.segmentBy}`
                      : name,
                  y:
                    this.props.segmentBy === "revenue_by_stage" ||
                    this.props.segmentBy === "revenue_by_owner"
                      ? d.revenue
                      : this.props.carrierPieChart
                      ? d.no_of_carrier
                      : d.count,
                };
              }),
            },
          ],
        },
      });
    }
  }
  setChart(chart) {
    this.chart = chart;
  }
  changeDataFormat = (e) => {
    const { value } = e.target;
    const segmentBy = this.props.segmentBy;
    const currency = this.props.companyOnboarding?.currency;
    const currencySymbol = CurrencyList.find((cur) => cur.code === currency);
    this.setState({
      dataFormat: value,
      chartOptions: {
        ...this.state.chartOptions,
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: -50,
              formatter: function () {
                const formattedCurrency = nFormatter(this.point.y, 1);
                return this.percentage < 10
                  ? ""
                  : value === "count"
                  ? segmentBy === "revenue_by_stage"
                    ? currencySymbol.symbol +
                      formattedCurrency.toLocaleString("en-US")
                    : this.point.y
                  : truncateDecimals(this.point.percentage, 1) + "%";
              },
            },
            showInLegend: true,
          },
        },
      },
    });
  };

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        <ReactTooltip id="reportNameOfPieComponent" />
        {this.props.isDefaultChart ? (
          <>
            <h3
              className="funnel-heading-sub"
              style={{
                marginBottom: "0",
                textDecoration: "underline",
                zIndex: "1",
                cursor: "pointer",
              }}
            >
              <div
                onClick={this.props.chartRedirectToReport}
                style={{
                  display: "inline-block",
                  textDecoration: "underline",
                  zIndex: "1",
                  cursor: "pointer",
                  marginBottom: "0",
                }}
                className="funnelHeading"
              >
                {`Lead Sources (${this.props.type})`}
              </div>
              <DropdownFunnel
                allFunnels={[
                  { name: "Lead Sources (Accounts)", id: "account" },
                  { name: "Lead Sources (Contacts)", id: "contact" },
                ]}
                getAllFunnels={this.props.setLeadSourceChartType}
                setRefresh={this.props.setRefresh}
              />
            </h3>
            <h6 className="funnel-heading-sub-c">
              <select
                value={this.state.dataFormat}
                onChange={this.changeDataFormat}
                className="funnel-select"
              >
                <option value="count">Count</option>
                <option value="percentage">Percentage</option>
              </select>
            </h6>
          </>
        ) : (
          <>
            <h3
              className="funnel-heading-sub"
              style={{
                marginBottom: "0",
                textDecoration: "underline",
                zIndex: "1",
                cursor: "pointer",
              }}
              onClick={this.props.chartRedirectToReport}
              data-tip={this.props.reportName}
              data-for="reportNameOfPieComponent"
            >
              {this.props.reportName.length > 30
                ? this.props.reportName.substring(0, 29) + ".."
                : this.props.reportName}
            </h3>
            <h6 className="funnel-heading-sub-c">
              <select
                value={this.state.dataFormat}
                onChange={this.changeDataFormat}
                className="funnel-select"
              >
                <option value="count">Count</option>
                <option value="percentage">Percentage</option>
              </select>
            </h6>
          </>
        )}
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          callback={(chart) => this.setChart(chart)}
        />
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
  };
};
const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
  };
};

export default connect(MSP, MDP)(PieGraphDashboard);
