import React, { Component } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import AccountsLogo from "../../images/AccountReport.svg";
import DealsLogo from "../../images/OpportunityReport.svg";
import ActivityLogo from "../../images/ActivitiesReport.svg";
import ContactsLogo from "../../images/ContactsNavIcon.svg";
import CarrierTabIcon from "../../images/CarrierTabIcon.svg";
import LaneIcon from "../../svg/LaneIcon";
import ClearIcon from "@mui/icons-material/Clear";
import { Plus } from "lucide-react";
import RequestQuoteOutlinedIcon from "@mui/icons-material/RequestQuoteOutlined";

class ReportsCreateModal extends Component {
  state = {
    showModal: true,
    reportName: "",
    reportDescription: null,
    reportType: "",
    errorMessage: false,
  };
  redirectButton = React.createRef();
  openFileSelect = () => this.redirectButton.current.click();
  handleOpenModal = () => {
    this.props.setShowOpportunityModal(true);
  };

  handleCloseModal = () => {
    this.props.setShowOpportunityModal(false);
  };

  showModal = () => {
    ReactModal.isOpen = false;
  };
  changeHandler = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  validateName = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  errorHandler = () => {
    if (this.state.reportName === "" || this.state.reportType === "") {
      this.setState({
        errorMessage: true,
      });
    }
  };
  submitHandler = (e) => {
    e.preventDefault();
    if (this.state.reportName === "" || this.state.reportType === "") {
      this.setState({
        errorMessage: true,
      });
      return false;
    }
    this.openFileSelect();
  };
  render() {
    const modalStyle = {
      content: {
        background: "transparent",
        border: "none",
        width: "0px",
      },
    };
    return (
      <div>
        <ReactModal
          isOpen={this.props.showOpportunityModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.props.handleCloseModal}
          style={modalStyle}
          ariaHideApp={false}
        >
          <div className="modal">
            <div
              className="modal__content"
              style={{ height: "67vh", top: "12%", paddingTop: "24px" }}
            >
              <div className="modal__header">
                {/* <span>
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                    }}
                  >
                    <i className="fa fa-info" aria-hidden="true"></i>
                  </div>
                </span> */}
                <div className="modal__head">
                  <h2 className="modal__heading">Create Report</h2>
                </div>
                <button
                  onClick={this.props.handleCloseModal}
                  className="modal__close"
                  style={{
                    border: "none",
                    background: "transparent",
                    cursor: "pointer",
                    outline: "none",
                  }}
                >
                  {" "}
                  <ClearIcon fontSize="small" />
                </button>
              </div>
              <form style={{ padding: "15px" }} onSubmit={this.submitHandler}>
                <label>Category</label>
                <div
                  style={
                    this.state.reportType === "accounts"
                      ? {
                          background: "#EEF6F2",
                          borderRadius: "8px",
                          width: "350px",
                        }
                      : { width: "350px" }
                  }
                >
                  <div style={{ transform: "translate(5px,5px)" }}>
                    <label style={{ display: "inline", margin: "0px" }}>
                      <img
                        src={AccountsLogo}
                        alt=""
                        width="20px"
                        style={{ transform: "translateY(5px)" }}
                      />
                      <span style={{ marginLeft: "4px" }}>Account</span>
                      <input
                        type="radio"
                        name="reportType"
                        value="accounts"
                        onChange={this.changeHandler}
                        style={{
                          display: "inline",
                          margin: "0px",
                          transform: "translateY(-17px)",
                        }}
                        required
                      />
                    </label>
                  </div>
                </div>
                {this.props.isFreight && (
                  <div
                    style={
                      this.state.reportType === "carriers"
                        ? {
                            background: "#EEF6F2",
                            borderRadius: "8px",
                            width: "350px",
                          }
                        : { width: "350px" }
                    }
                  >
                    <div style={{ transform: "translate(5px,5px)" }}>
                      <label style={{ display: "inline", margin: "0px" }}>
                        <img
                          src={CarrierTabIcon}
                          alt=""
                          width="20px"
                          style={{ transform: "translateY(5px)" }}
                        />
                        <span style={{ marginLeft: "4px" }}>Carriers</span>
                        <input
                          type="radio"
                          name="reportType"
                          value="carriers"
                          onChange={this.changeHandler}
                          style={{
                            display: "inline",
                            margin: "0px",
                            transform: "translateY(-17px)",
                          }}
                          required
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div
                  style={
                    this.state.reportType === "opportunities"
                      ? {
                          background: "#EEF6F2",
                          borderRadius: "8px",
                          width: "350px",
                        }
                      : { width: "350px" }
                  }
                >
                  <div style={{ transform: "translate(5px,5px)" }}>
                    <label style={{ display: "inline", margin: "0px" }}>
                      <img
                        src={DealsLogo}
                        alt=""
                        width="20px"
                        style={{ transform: "translateY(5px)" }}
                      />
                      <span style={{ marginLeft: "4px" }}>Deals</span>
                      <input
                        type="radio"
                        name="reportType"
                        value="opportunities"
                        onChange={this.changeHandler}
                        style={{
                          display: "inline",
                          margin: "0px",
                          transform: "translateY(-17px)",
                        }}
                        required
                      />
                    </label>
                  </div>
                </div>
                <div
                  style={
                    this.state.reportType === "contacts"
                      ? {
                          background: "#EEF6F2",
                          borderRadius: "8px",
                          width: "350px",
                        }
                      : { width: "350px" }
                  }
                >
                  <div style={{ transform: "translate(5px,5px)" }}>
                    <label style={{ display: "inline", margin: "0px" }}>
                      <img
                        src={ContactsLogo}
                        alt=""
                        width="20px"
                        style={{ transform: "translateY(5px)" }}
                      />
                      <span style={{ marginLeft: "4px" }}>Contacts</span>
                      <input
                        type="radio"
                        name="reportType"
                        value="contacts"
                        onChange={this.changeHandler}
                        style={{
                          display: "inline",
                          margin: "0px",
                          transform: "translateY(-17px)",
                        }}
                        required
                      />
                    </label>
                  </div>
                </div>
                {this.props.isFreight && (
                  <div
                    style={
                      this.state.reportType === "lanes"
                        ? {
                            background: "#EEF6F2",
                            borderRadius: "8px",
                            width: "350px",
                          }
                        : { width: "350px" }
                    }
                  >
                    <div style={{ transform: "translate(5px,5px)" }}>
                      <label style={{ display: "inline", margin: "0px" }}>
                        <div
                          style={{
                            display: "inline-block",
                            marginRight: "4px",
                            transform: `translateY(4px)`,
                          }}
                        >
                          <LaneIcon fill="#A6A6B0" />
                        </div>
                        <span style={{ marginLeft: "6px" }}>Lanes</span>
                        <input
                          type="radio"
                          name="reportType"
                          value="lanes"
                          onChange={this.changeHandler}
                          style={{
                            display: "inline",
                            margin: "0px",
                            transform: "translateY(-17px)",
                          }}
                          required
                        />
                      </label>
                    </div>
                  </div>
                )}
                {this.props.isFreight && (
                  <div
                    style={
                      this.state.reportType === "lane_quotes"
                        ? {
                            background: "#EEF6F2",
                            borderRadius: "8px",
                            width: "350px",
                          }
                        : { width: "350px" }
                    }
                  >
                    <div style={{ transform: "translate(5px,5px)" }}>
                      <label style={{ display: "inline", margin: "0px" }}>
                        <div
                          style={{
                            display: "inline-block",
                            marginRight: "4px",
                            transform: "translate(-15px, -21px)",
                            width: "20px",
                          }}
                        >
                          <RequestQuoteOutlinedIcon
                            className="a-type"
                            fontSize="small"
                            sx={{ color: "#A6A6B0" }}
                          />
                        </div>
                        <span style={{ marginLeft: "6px" }}>Quotes</span>
                        <input
                          type="radio"
                          name="reportType"
                          value="lane_quotes"
                          onChange={this.changeHandler}
                          style={{
                            display: "inline",
                            margin: "0px",
                            transform: "translateY(-17px)",
                          }}
                          required
                        />
                      </label>
                    </div>
                  </div>
                )}
                <div
                  style={
                    this.state.reportType === "activities"
                      ? {
                          background: "#EEF6F2",
                          borderRadius: "8px",
                          width: "350px",
                        }
                      : { width: "350px" }
                  }
                >
                  <div style={{ transform: "translate(5px,5px)" }}>
                    <label style={{ display: "inline", margin: "0px" }}>
                      <img
                        src={ActivityLogo}
                        alt=""
                        width="20px"
                        style={{ transform: "translateY(5px)" }}
                      />
                      <span style={{ marginLeft: "4px" }}>Activities</span>
                      <input
                        type="radio"
                        name="reportType"
                        value="activities"
                        onChange={this.changeHandler}
                        style={{
                          display: "inline",
                          margin: "0px",
                          transform: "translateY(-17px)",
                        }}
                        required
                      />
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.reportType === null ||
                    this.state.reportType === "")
                    ? "Please select a category"
                    : null}
                </div>
                <label>Reports Name</label>
                <input
                  name="reportName"
                  type="text"
                  value={this.state.reportName}
                  onChange={this.changeHandler}
                  required
                />
                <div
                  style={{
                    color: "#F36363",
                    fontSize: "12px",
                    marginBottom: "5px",
                  }}
                >
                  {this.state.errorMessage === true &&
                  (this.state.reportName === null ||
                    this.state.reportName === "")
                    ? "Name is required"
                    : null}
                </div>
                <label>Description</label>
                <textarea
                  name="reportDescription"
                  value={this.state.reportDescription}
                  onChange={this.changeHandler}
                  style={{ fontFamily: "inherit" }}
                  maxlength="140"
                ></textarea>

                <div className="modal__buttons">
                  <button
                    type="button"
                    className="modal__cancel-button"
                    onClick={this.props.handleCloseModal}
                    style={{
                      position: "absolute",
                      bottom: "10px",
                    }}
                  >
                    Cancel
                  </button>
                  {this.state.reportType === "" ||
                  this.state.reportName === "" ? (
                    <Link
                      className="button-md"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "15%",
                        width: "auto",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                      type="button"
                      onClick={() => this.errorHandler()}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Create</span>
                        <Plus
                          size={14}
                          color="#62CA9D"
                          strokeWidth={3}
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </span>
                    </Link>
                  ) : (
                    <Link
                      to={{
                        pathname: `/reports/${this.state.reportType}/save`,
                        state: {
                          reportType: this.state.reportType,
                          reportName: this.state.reportName,
                          reportDescription:
                            this.state.reportDescription === ""
                              ? null
                              : this.state.reportDescription,
                        },
                      }}
                      ref={this.redirectButton}
                      className="button-md"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "15%",
                        width: "auto",
                        textDecoration: "none",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "inline-block",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>Create</span>
                        <Plus
                          size={14}
                          color="#62CA9D"
                          strokeWidth={3}
                          style={{
                            marginLeft: "5px",
                          }}
                        />
                      </span>
                    </Link>
                  )}
                </div>
              </form>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

export default ReportsCreateModal;
