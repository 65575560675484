import React, { Component } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import axios from "axios";
import DropdownDownload from "../../Dropdown/DropdownDownload";
import { NotificationManager } from "react-notifications";

class PieGraph extends Component {
  state = {
    // To avoid unnecessary update keep all options in the state.
    downloadLoader: false,
    chartOptions: {
      credits: {
        enabled: false,
      },
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
      },
      title: {
        text: "Pie Chart",
      },
      tooltip: {
        pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: `<b>{point.name}: {point.y} ${
              this.props.segmentBy === "revenue_by_stage"
                ? this.props.currency
                : ""
            }</b> <br />{point.percentage:.1f} %`,
          },
        },
      },
      series: [
        {
          name: this.props.type,
          colorByPoint: true,
          data: this.props.data?.map((d) => {
            let name = d.source;
            name =
              this.props.segmentBy === "source"
                ? d.source
                : this.props.segmentBy === "account_owner"
                ? d.account_owner
                : d.status;
            return { name, y: d.count };
          }),
        },
      ],
    },
    hoverData: null,
  };

  setHoverData = (e) => {
    // The chart is not updated because `chartOptions` has not changed.
    this.setState({ hoverData: e.target.category });
  };

  componentDidMount() {
    this.setState({
      chartOptions: {
        ...this.state.chartOptions,
        series: [
          {
            name: this.props.type,
            colorByPoint: true,
            data: this.props.data?.map((d) => {
              let name = d.source;
              name =
                this.props.segmentBy === "source"
                  ? d.source
                  : this.props.segmentBy === "account_owner"
                  ? d.account_owner
                  : this.props.segmentBy === "contact_owner"
                  ? d.contact_owner
                  : this.props.segmentBy === "carrier_owner"
                  ? d.carrier_owner
                  : this.props.segmentBy === "opportunity_owner" ||
                    this.props.segmentBy === "revenue_by_owner"
                  ? d.opportunity_owner
                  : this.props.segmentBy === "stage"
                  ? d.stage
                  : this.props.segmentBy === "close_date"
                  ? d.close_date
                  : this.props.segmentBy === "opportunity_owner"
                  ? d.opportunity_owner
                  : this.props.segmentBy === "revenue_by_stage"
                  ? d.revenue_by_stage
                  : this.props.segmentBy === "created_date"
                  ? d.created_date
                  : this.props.segmentBy === "type"
                  ? d.type
                  : this.props.segmentBy === "category"
                  ? d.name
                  : this.props.segmentBy === "activity_owner"
                  ? d.activity_owner
                  : this.props.segmentBy === "result"
                  ? d.result
                  : this.props.segmentBy === "status"
                  ? d.status
                  : d.cf_value ||
                    d.bar_name ||
                    d.origin_state ||
                    d.destination_state ||
                    d.account_name ||
                    d.carrier_name ||
                    d.name;
              return {
                name:
                  name === null
                    ? Number.isInteger(parseInt(this.props.segmentBy))
                      ? `No value`
                      : `No ${this.props.segmentBy}`
                    : name,
                y:
                  this.props.segmentBy === "revenue_by_stage" ||
                  this.props.segmentBy === "revenue_by_owner"
                    ? d.revenue
                    : this.props.carrierPieChart
                    ? d.no_of_carrier
                    : d.count,
              };
            }),
          },
        ],
      },
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.refresh !== prevProps.refresh) {
      this.setState({
        chartOptions: {
          ...this.state.chartOptions,
          series: [
            {
              name: this.props.type,
              colorByPoint: true,
              data: this.props.data?.map((d) => {
                let name = d.source;
                name =
                  this.props.segmentBy === "source"
                    ? d.source
                    : this.props.segmentBy === "account_owner"
                    ? d.account_owner
                    : this.props.segmentBy === "contact_owner"
                    ? d.contact_owner
                    : this.props.segmentBy === "carrier_owner"
                    ? d.carrier_owner
                    : this.props.segmentBy === "opportunity_owner" ||
                      this.props.segmentBy === "revenue_by_owner"
                    ? d.opportunity_owner
                    : this.props.segmentBy === "stage"
                    ? d.stage
                    : this.props.segmentBy === "close_date"
                    ? d.close_date
                    : this.props.segmentBy === "opportunity_owner"
                    ? d.opportunity_owner
                    : this.props.segmentBy === "revenue_by_stage"
                    ? d.revenue_by_stage
                    : this.props.segmentBy === "created_date"
                    ? d.created_date
                    : this.props.segmentBy === "type"
                    ? d.type
                    : this.props.segmentBy === "category"
                    ? d.name
                    : this.props.segmentBy === "activity_owner"
                    ? d.activity_owner
                    : this.props.segmentBy === "result"
                    ? d.result
                    : this.props.segmentBy === "status"
                    ? d.status
                    : d.cf_value ||
                      d.bar_name ||
                      d.origin_state ||
                      d.destination_state ||
                      d.account_name ||
                      d.carrier_name ||
                      d.name;
                return {
                  name:
                    name === null
                      ? Number.isInteger(parseInt(this.props.segmentBy))
                        ? `No value`
                        : `No ${this.props.segmentBy}`
                      : name,
                  y:
                    this.props.segmentBy === "revenue_by_stage" ||
                    this.props.segmentBy === "revenue_by_owner"
                      ? d.revenue
                      : this.props.carrierPieChart
                      ? d.no_of_carrier
                      : d.count,
                };
              }),
            },
          ],
        },
      });
    }
  }
  download = (typeParam) => {
    let downloadType =
      typeParam === "pdf"
        ? "application/pdf"
        : typeParam === "png"
        ? "image/png"
        : typeParam === "svg+xml"
        ? "image/svg+xml"
        : "image/jpeg";
    let data = {
      options: JSON.stringify(this.state.chartOptions),
      filename: "PieChart",
      type: downloadType,
      b64: true,
    };
    this.setState({
      downloadLoader: true,
    });

    axios({
      method: "post",
      url: "https://export.highcharts.com/",
      data: data,
    })
      .then((response) => {
        var a = document.createElement("a"); //Create <a>
        a.href = `data:${downloadType};base64,${response.data}`; //Image Base64 Goes here
        a.download =
          typeParam === "svg+xml" ? `Chart.xml` : `Chart.${typeParam}`; //File name Here
        a.click();
        this.setState({
          downloadLoader: false,
        });
      })
      .catch((error) => {
        //handle error
        NotificationManager.error("Error downloading chart");
        console.log(error);
        this.setState({
          downloadLoader: false,
        });
      });
  };

  render() {
    const { chartOptions } = this.state;
    return (
      <div>
        {this.props.fromDashboard !== true && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              height: "37px",
            }}
          >
            <DropdownDownload
              download={this.download}
              downloadLoader={this.state.downloadLoader}
            />
          </div>
        )}
        <HighchartsReact highcharts={Highcharts} options={chartOptions} />
      </div>
    );
  }
}

export default PieGraph;
