export const quoteFilters = [
    {
      label: "Quote Owners",
      value: "owner",
    },
    { label: "Ship Date", value: "shipment_date" },
    { label: "Origin City", value: "origin_city" },
    { label: "Origin State", value: "origin_state" },
    { label: "Origin Zip", value: "origin_zip" },
    { label: "Origin Address", value: "origin_address" },
    { label: "Destination City", value: "destination_city" },
    { label: "Destination State", value: "destination_state" },
    { label: "Destination Zip", value: "destination_zip" },
    { label: "Destination Address", value: "destination_address" },
    // { label: "Created Date", value: "created_date" },
    { label: "Status", value: "status_id" },
    { label: "Batch", value: "batch" },
    { label: "Quote", value: "price" },
    { label: "Equipment Types", value: "equipment_type_ids" },
    { label: "Special Requirements", value: "requirement_type_ids" },
    { label: "Modes", value: "mode_ids" },
  ];
  