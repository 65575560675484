export const laneFilters = [
  {
    label: "Lane Owners",
    value: "owner",
  },
  {
    label: "Account Name",
    value: "account_name",
  },
  { label: "Carrier Name", value: "carrier_name" },
  { label: "Origin City", value: "origin_city" },
  { label: "Origin State", value: "origin_state" },
  { label: "Origin Zip", value: "origin_zip" },
  { label: "Origin Address", value: "origin_address" },
  { label: "Destination City", value: "destination_city" },
  { label: "Destination State", value: "destination_state" },
  { label: "Destination Zip", value: "destination_zip" },
  { label: "Destination Address", value: "destination_address" },
  { label: "Created Date", value: "created_date" },
  { label: "Batch", value: "batch" },
  { label: "Linehaul", value: "price" },
  { label: "Rate per Mile", value: "rate_per_mile" },
  { label: "Days of Week", value: "days_of_week" },
  // { label: "Status", value: "status_id" },
  { label: "Volume", value: "volume" },
  { label: "Equipment Types", value: "equipment_type_ids" },
  { label: "Special Requirements", value: "requirement_type_ids" },
  { label: "Modes", value: "mode_ids" },
  { label: "Lane Types", value: "type_ids" },
];
