import React, { Component } from "react";
import "../../../sass/CustomerProfile.scss";
import "../../../sass/LanesPanel.scss";
import axios from "axios";
import LaneIcon from "../../../images/lane.svg";
import arrayMove from "array-move";
import {
  GroupModal,
  FieldModal,
  EditFieldModal,
  DeleteFieldModal,
  DeleteGroupModal,
  EditGroupModal,
} from "../../AccountModals";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { NotificationManager } from "react-notifications";
import StatusChangeModal from "../../Accounts/StatusChangeModal";
import YoutubePlayer from "../../YoutubePlayer";
import CustomFieldHelperText from "./CustomFieldHelperText";
import LaneCustomSort from "./LaneCustomSort";
// Redux stuff
import { connect } from "react-redux";
import { companyOnboardingSet, fetchAllLaneCustomFields } from "../../../redux";
import { Plus } from "lucide-react";

const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "white",
    outline: "#C0EAD8",
    margin: "5px auto",
    // height: "35px",
    boxShadow: "none",
  }),
  indicatorSeparator: (styles) => {
    return {
      display: "none",
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      // height: "37px",
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? "#3aab7b"
        : isFocused
        ? "#C0EAD8"
        : null,
      cursor: isDisabled ? "not-allowed" : "default",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled && (isSelected ? data.color : "#C0EAD8"),
      },
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#C0EAD8",
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "black",
  }),
};

class LaneCustom extends Component {
  state = {
    fieldTypes: [],
    label: "",
    fieldLabel: "",
    editFieldLabel: "",
    editFieldType: "",
    editFieldChoices: [],
    accountFields: [],
    showOpportunityModal: false,
    showGroupModal: false,
    showEditModal: false,
    showDeleteModal: false,
    showGroupDeleteModal: false,
    fieldType: "",
    accountField: "",
    addFieldModalGroupId: "",
    fieldChoices: [
      { tempId: 1, value: "", position: 1 },
      { tempId: 2, value: "", position: 2 },
    ],
    hasChoices: false,
    currentField: {},
    currentFieldType: "",
    currentGroup: {},
    parentId: "",
    errorMessage: false,
    windowWidth: window.innerWidth,
    isLoading: false,
    showGroupEditModal: false,
    showStatusModal: false,
    showYoutubeVideo: false,
    fieldRequired: false,
    defaultFields: {
      name: { required: true },
      phone: { required: false },
      phone_ext: { required: false },
      email: { required: false },
      address: { required: false },
      city: { required: false },
      state: { required: false },
      zip: { required: false },
      country: { required: false },
      preferred_communication: { required: false },
      source: { required: false },
      website: { required: false },
      description: { required: false },
    },
  };
  fetchRequiredFields = () => {
    axios({
      method: "GET",
      url: `/admin/settings`,
    }).then((res) =>
      this.setState({
        defaultFields: res.data.settings.account,
      })
    );
  };
  handleOpenModal = (groupId) => {
    this.setState({
      showOpportunityModal: true,
      addFieldModalGroupId: groupId,
    });
  };
  handleGroupOpenModal = () => {
    this.setState({
      showGroupModal: true,
    });
  };
  handleGroupEditOpenModal = (fieldId) => {
    this.setState((prevState) => ({
      showGroupEditModal: true,
      currentGroup: prevState.accountFields.find(
        (field) => field.id === fieldId
      ),
      editFieldLabel: this.state.currentGroup.label,
      editFieldTypel: this.state.currentGroup.type,
    }));
  };
  handleGroupEditCloseModal = () => {
    this.setState({
      showGroupEditModal: false,
    });
  };
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth });
  };
  handleOpenStatusModal = () => {
    this.setState({
      showStatusModal: true,
    });
  };
  handleCloseStatusModal = () => {
    this.setState({
      showStatusModal: false,
    });
  };
  handleDeleteOpenModal = (fieldId, parentId) => {
    this.setState((prevState) => ({
      currentField: prevState.accountFields
        .find((field) => field.id === parentId)
        .fields.find((field) => field.id === fieldId),
      showDeleteModal: true,
      editFieldLabel: this.state.currentField.label,
      editFieldTypel: this.state.currentField.type,
    }));
  };
  handleGroupDeleteOpenModal = (fieldId) => {
    this.setState((prevState) => ({
      currentGroup: prevState.accountFields.find(
        (field) => field.id === fieldId
      ),
      showGroupDeleteModal: true,
      editFieldLabel: this.state.currentGroup.label,
      editFieldTypel: this.state.currentGroup.type,
    }));
  };
  handleEditOpenModal = (fieldId, parentId) => {
    this.setState((prevState) => ({
      currentField: prevState.accountFields
        .find((field) => field.id === parentId)
        .fields.find((field) => field.id === fieldId),
      showEditModal: true,
      editFieldLabel: this.state.currentField.label,
      editFieldTypel: this.state.currentField.type,
    }));
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleCloseModal = () => {
    this.setState({
      showOpportunityModal: false,
    });
  };
  handleGroupCloseModal = () => {
    this.setState({
      showGroupModal: false,
    });
  };
  handleDeleteCloseModal = () => {
    this.setState({
      showDeleteModal: false,
    });
  };
  handleGroupDeleteCloseModal = () => {
    this.setState({
      showGroupDeleteModal: false,
    });
  };
  handleEditCloseModal = () => {
    this.setState({
      showEditModal: false,
      editFieldChoices: [],
    });
  };
  addChoice = () => {
    this.setState((prevState) => ({
      fieldChoices: [
        ...prevState.fieldChoices,
        {
          tempId: uuidv4(),
          value: "",
          position: prevState.fieldChoices.length + 1,
        },
      ],
    }));
  };
  addEditFieldChoice = () => {
    this.setState((prevState) => ({
      editFieldChoices: [
        ...prevState.editFieldChoices,
        {
          tempId: uuidv4(),
          value: "",
          position: prevState.editFieldChoices.length + 1,
        },
      ],
    }));
  };

  deleteEditChoiceNewChangeHandler = (tempId, e) => {
    this.setState((prevState) => ({
      editFieldChoices: [
        ...prevState.editFieldChoices.filter(
          (choice) => choice.tempId !== tempId
        ),
      ],
    }));
  };
  groupLabelChangeHandler = (e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      currentGroup: { ...prevState.currentGroup, label: value },
    }));
  };

  getFieldsAccount = () => {
    axios({
      method: "GET",
      url: `/fields/lane`,
    }).then((res) => {
      this.setState({
        accountFields: res.data.fields,
      });
    });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.getFieldsAccount();
    this.fetchRequiredFields();
    axios({
      method: "GET",
      url: `/fields/types`,
    }).then((res) => {
      this.setState({
        fieldTypes: res.data.types,
      });
    });
  }
  addGroupHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.label) {
      this.setState({
        errorMessage: true,
      });
      return false;
    }
    axios({
      method: "POST",
      url: `/fields`,

      data: {
        label: this.state?.label,
        type: "section",
        field_class: "lane",
      },
    })
      .then((response) => {
        this.handleGroupCloseModal();
        this.getFieldsAccount();
        this.props.fetchAllLaneCustomFields();
        NotificationManager.success("Group added successfully.");
        this.setState({
          label: "",
          errorMessage: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error("Group name already exists");
        }
      });
  };
  showPreview = () => {
    const type = this.state.fieldType;
    const choices = this.state.fieldChoices;
    const label = this.state.fieldLabel;
    const required = this.state.fieldRequired;
    switch (type) {
      case "paragraph":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <textarea></textarea>
          </div>
        );
      case "text":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "hyperlink":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "revenue":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="text" />
          </div>
        );
      case "decimal":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <input type="number" />
          </div>
        );
      case "dropdown":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <select>
              <option>- Select -</option>
              {choices.map((choice) => (
                <option>{choice.value}</option>
              ))}
            </select>
          </div>
        );
      case "checkbox":
        return (
          <div style={{ textAlign: "left" }}>
            <input
              type="checkbox"
              style={{
                display: "inline-block",
                textAlign: "left",
                width: "14px",
                marginRight: "10px",
              }}
            />
            <label
              style={{
                display: "inline-block",
                textAlign: "left",
                fontSize: "14px",
              }}
            >
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
          </div>
        );
      case "radio":
        return (
          <div style={{ textAlign: "left" }}>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            {choices.map((choice) => (
              <div key={choice.id}>
                <input
                  type="radio"
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    width: "14px",
                    marginRight: "10px",
                  }}
                  id={choice.id + choice.value}
                  name="radioButtonPreview"
                />
                <label
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    fontSize: "14px",
                  }}
                  htmlFor={choice.id + choice.value}
                >
                  {choice.value}
                </label>
              </div>
            ))}
          </div>
        );
      case "date":
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <DatePicker
              selected={new Date()}
              onChange={(date) => Date.parse(date)}
              dateFormat={
                this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/MM/yyyy"
                  : "MM/dd/yyyy"
              }
              placeholderText={
                this.props.companyOnboarding?.date_format === "DD/MM/YYYY"
                  ? "dd/mm/yyyy"
                  : "mm/dd/yyyy"
              }
            />
          </div>
        );
      case "multi_select_dropdown":
        const colourStyles = {
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            width: "280px",
            outline: "#C0EAD8",
            minHeight: "30px",
            padding: "5px auto",
          }),
          option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
              ...styles,
              backgroundColor: isDisabled
                ? null
                : isSelected
                ? data.color
                : isFocused
                ? "#C0EAD8"
                : null,
              cursor: isDisabled ? "not-allowed" : "default",

              ":active": {
                ...styles[":active"],
                backgroundColor:
                  !isDisabled && (isSelected ? data.color : "#C0EAD8"),
              },
            };
          },
          multiValue: (styles, { data }) => {
            return {
              ...styles,
              backgroundColor: "#C0EAD8",
            };
          },
          multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "black",
            fontSize: "13px",
          }),
        };
        return (
          <div>
            <label>
              {label}{" "}
              {required && (
                <span
                  style={{
                    color: "#4A9876",
                    fontSize: "17px",
                    lineHeight: "16px",
                  }}
                >
                  *
                </span>
              )}
            </label>
            <Select
              closeMenuOnSelect={false}
              isMulti
              styles={colourStyles}
              options={choices.map((choice) => {
                const { value } = choice;
                return { value: value, label: value };
              })}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        );
      default: {
      }
    }
  };
  addFieldHandler = (e) => {
    e.preventDefault();
    const data = this.state;
    if (!data.fieldLabel || !data.fieldType) {
      this.setState({ errorMessage: true });
      return false;
    } else if (
      (data.fieldType === "dropdown" ||
        data.fieldType === "radio" ||
        data.fieldType === "multi_select_dropdown") &&
      data.fieldChoices.length < 2
    ) {
      this.setState({ errorMessage: true });
      return false;
    } else if (
      (data.fieldType === "dropdown" ||
        data.fieldType === "radio" ||
        data.fieldType === "multi_select_dropdown") &&
      data.fieldChoices.map((choice) => choice.value === "").includes(true)
    ) {
      this.setState({ errorMessage: true });
      return false;
    }
    this.setState({ isLoading: true });
    axios({
      method: "POST",
      url: `/fields`,
      data: {
        label: this.state?.fieldLabel,
        field_class: "lane",
        type: this.state.fieldType,
        required: this.state.fieldRequired ? true : false,
        parent_id: this.state.addFieldModalGroupId,
        choices: this.state.fieldChoices.map((choice) => {
          return {
            value: choice.value,
          };
        }),
      },
    })
      .then((response) => {
        this.handleCloseModal();
        this.getFieldsAccount();
        this.props.fetchAllLaneCustomFields();
        NotificationManager.success("Field added successfully.");
        this.setState({
          fieldLabel: "",
          fieldType: "",
          addFieldModalGroupId: "",
          fieldChoices: [
            { tempId: 1, value: "", position: 1 },
            { tempId: 2, value: "", position: 2 },
          ],
          fieldRequired: false,
          errorMessage: false,
          isLoading: false,
        });
      })
      .catch((error) => {
        if (error.response.status === 409) {
          NotificationManager.error("Field name already exists");
        } else {
          NotificationManager.error("Error creating field");
        }
        this.setState({ isLoading: false });
      });
  };
  editFieldChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      currentField: {
        ...prevState.currentField,
        [name]: value,
      },
    }));
  };
  editRequiredFieldChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        currentField: {
          ...prevState.currentField,
          required: value,
        },
      }));
    } else {
      this.setState((prevState) => ({
        currentField: {
          ...prevState.currentField,
          required: null,
        },
      }));
    }
  };
  editChoiceChangeHandler = (choiceId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      currentField: {
        ...prevState.currentField,
        choices: prevState.currentField.choices.map((choice) => {
          if (choice.id === choiceId) {
            choice.value = value;
          }
          return choice;
        }),
      },
    }));
  };
  deleteChoiceChangeHandler = (choiceId, e) => {
    this.setState((prevState) => ({
      currentField: {
        ...prevState.currentField,
        choices: prevState.currentField.choices.map((choice) => {
          if (choice.id === choiceId) {
            choice.destroy = true;
          }
          return choice;
        }),
      },
    }));
  };

  deleteChoiceNewChangeHandler = (tempId, e) => {
    this.setState((prevState) => ({
      fieldChoices: [
        ...prevState.fieldChoices.filter((choice) => choice.tempId !== tempId),
      ],
    }));
  };

  editFieldHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/fields/${this.state.currentField.id}`,

      data: {
        label: this.state?.currentField.label,
        field_class: "lane",
        type: this.state?.currentField.type,
        required: this.state?.currentField.required ? true : false,
        parent_id: this.state.currentField.parent_id,
        destroy: false,
        position: this.state?.currentField.position,
        choices: [
          ...this.state?.currentField?.choices?.map((choice) => {
            return {
              position: choice.position,
              value: choice.value,
              destroy: choice.destroy || false,
              choice_id: choice.id,
            };
          }),
          ...this.state.editFieldChoices.map((choice) => {
            return {
              position: choice.position,
              value: choice.value,
              destroy: choice.destroy || false,
            };
          }),
        ],
      },
    })
      .then((response) => {
        this.setState({ isLoading: false });
        this.handleEditCloseModal();
        this.getFieldsAccount();
        this.props.fetchAllLaneCustomFields();
        NotificationManager.success("Field edited successfully.");
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        NotificationManager.error("Error editing field");
      });
  };
  deleteFieldHandler = (e) => {
    e.preventDefault();
    axios({
      method: "PUT",
      url: `/fields/${this.state.currentField.id}`,
      data: {
        // field_id: this.state?.currentField.id,
        label: this.state?.currentField.label,
        // name: this.state?.currentField.name,
        field_class: "lane",
        type: this.state?.currentField.type,
        required: false,
        parent_id: this.state?.currentField.parent_id,
        destroy: true,
        position: this.state?.currentField.position,
        choices: this.state?.currentField?.choices?.map((choice) => {
          return {
            position: choice.position,
            value: choice.value,
            destroy: false,
          };
        }),
      },
    })
      .then((response) => {
        this.getFieldsAccount();
        this.props.fetchAllLaneCustomFields();
        this.handleDeleteCloseModal();
        NotificationManager.success("Field deleted successfully.");
      })
      .catch((error) => NotificationManager.error("Error deleting field"));
  };

  deleteGroupHandler = (e) => {
    e.preventDefault();
    if (this.state.currentGroup.fields.length === 0) {
      axios({
        method: "PUT",
        url: `/fields/${this.state.currentGroup.id}`,
        data: {
          //   field_id: this.state?.currentGroup.id,
          label: this.state?.currentGroup.label,
          //   name: this.state?.currentGroup.name,
          field_class: "lane",
          type: this.state?.currentGroup.type,
          required: false,
          parent_id: this.state?.currentGroup.parent_id,
          destroy: true,
          position: this.state?.currentGroup.position,
          choices: this.state?.currentGroup?.choices?.map((choice) => {
            return {
              position: choice.position,
              value: choice.value,
              destroy: false,
            };
          }),
        },
      })
        .then((response) => {
          this.getFieldsAccount();
          this.props.fetchAllLaneCustomFields();
          this.handleGroupDeleteCloseModal();
          NotificationManager.success("Group deleted successfully.");
        })
        .catch((error) => NotificationManager.error("Error deleting field"));
    } else {
      NotificationManager.error("Group must be empty to be deleted");
    }
  };
  editGroupHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    axios({
      method: "PUT",
      url: `/fields/${this.state.currentGroup.id}`,
      data: {
        //   field_id: this.state?.currentGroup.id,
        label: this.state?.currentGroup.label,
        //   name: this.state?.currentGroup.name,
        field_class: "lane",
        type: this.state?.currentGroup.type,
        required: false,
        parent_id: this.state?.currentGroup.parent_id,
        destroy: false,
        position: this.state?.currentGroup.position,
        choices: this.state?.currentGroup?.choices?.map((choice) => {
          return {
            position: choice.position,
            value: choice.value,
            destroy: false,
          };
        }),
      },
    })
      .then((response) => {
        this.setState({ isLoading: false });
        this.getFieldsAccount();
        this.props.fetchAllLaneCustomFields();
        this.handleGroupEditCloseModal();
        NotificationManager.success("Group edited successfully.");
      })
      .catch((error) => {
        NotificationManager.error("Error editing group");
        this.setState({ isLoading: false });
      });
  };
  changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "fieldChoices") {
      this.setState((prevState) => ({
        fieldChoices: [prevState.fieldChoices, value],
      }));
    }
    if (name === "fieldType") {
      this.setState((prevState) => ({
        [name]: value,
        hasChoices: prevState.fieldTypes.find((type) => type.name === value)
          .has_choices,
      }));
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  fieldRequiredCheckBoxChangeHandler = (e) => {
    const { value } = e.target;
    if (e.target.checked === true) {
      this.setState((prevState) => ({
        fieldRequired: value,
      }));
    } else {
      this.setState((prevState) => ({
        fieldRequired: null,
      }));
    }
  };
  changeNewChoiceHandler = (tempId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      fieldChoices: prevState.fieldChoices.map((choice) => {
        if (choice.tempId === tempId) {
          choice.value = value;
        }
        return choice;
      }),
    }));
  };
  changeEditFieldNewChoiceHandler = (tempId, e) => {
    const { value } = e.target;
    this.setState((prevState) => ({
      editFieldChoices: prevState.editFieldChoices.map((choice) => {
        if (choice.tempId === tempId) {
          choice.value = value;
        }
        return choice;
      }),
    }));
  };
  onSortEnd = ({ oldIndex, newIndex }) => {
    const currentField = this.state.accountFields[oldIndex];
    this.setState(({ accountFields }) => ({
      accountFields: arrayMove(accountFields, oldIndex, newIndex),
    }));
    axios({
      method: "PUT",
      url: `/fields/${currentField.id}`,
      data: {
        // field_id: currentField.id,
        label: currentField.label,
        // name: currentField.name,
        field_class: "lane",
        type: currentField.type,
        required: false,
        // parent_id: currentField.parent_id || null,
        destroy: false,
        position: newIndex + 1,
        choices: currentField?.choices?.map((choice) => {
          return {
            position: choice.position,
            value: choice.value,
            destroy: false,
          };
        }),
      },
    });
  };
  onFieldSortEnd = ({ oldIndex, newIndex }, parent_id) => {
    const currentField = this.state.accountFields.find(
      (field) => field.id === parent_id
    ).fields[oldIndex];
    this.setState(({ accountFields }) => ({
      accountFields: accountFields.map((field) => {
        if (field.id === parent_id) {
          field.fields = arrayMove(field.fields, oldIndex, newIndex);
        }
        return field;
      }),
    }));
    axios({
      method: "PUT",
      url: `/fields/${currentField.id}`,

      data: {
        // field_id: currentField.id,
        label: currentField.label,
        // name: currentField.name,
        field_class: "lane",
        type: currentField.type,
        required: currentField.required,
        parent_id: parent_id,
        destroy: false,
        position: newIndex + 1,
        choices: currentField?.choices?.map((choice) => {
          return {
            position: choice.position,
            value: choice.value,
            destroy: false,
          };
        }),
      },
    });
  };

  render() {
    const groupId = this.state?.accountFields?.[0]?.id;
    const group = this.state?.accountFields?.[0];
    return (
      <div className="customerProfile">
        <div className="row">
          <div className="col-2-of-3" style={{ marginRight: "0" }}>
            <div className="lanes-panel">
              <h3 className="activity-title">
                <span
                  style={{
                    display: "inline-block",
                    marginRight: "5px",
                  }}
                >
                  <div
                    className="customerInfo-icon-modal"
                    style={{
                      transform: "translateY(0px)",
                      background: "rgb(255, 227, 179)",
                      width: "30px",
                      height: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={LaneIcon}
                      alt="+ Lane"
                      style={{ width: "17px" }}
                    />
                  </div>
                </span>
                Add Lane
                {/* <button
                  type="button"
                  onClick={this.handleOpenStatusModal}
                  className="button-sm"
                  style={{ marginLeft: "10px" }}
                >
                  Status +
                </button> */}
              </h3>
              <div className="lanes-panel-form">
                <div>
                  <label>Origin</label>
                  <input />
                </div>
                <div>
                  <label>Destination</label>
                  <input />
                </div>
                <div>
                  <label>Linehaul</label>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "14px",
                        left: "8px",
                        fontWeight: "500",
                      }}
                    >
                      $
                    </div>
                    <input
                      style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label>Rate Per Mile</label>
                  <div style={{ position: "relative" }}>
                    <div
                      style={{
                        position: "absolute",
                        top: "14px",
                        left: "8px",
                        fontWeight: "500",
                      }}
                    >
                      $
                    </div>
                    <input
                      style={{ paddingLeft: "20px", width: "calc(95% - 20px)" }}
                      type="number"
                    />
                  </div>
                </div>
                <div>
                  <label>Volume (Weekly)</label>
                  <input type="number" />
                </div>
              </div>
              <div className="lanes-panel-form-1">
                <div className="lanes-panel-form-1-div">
                  <label>Equipment Types</label>
                  <Select
                    // value={equipmentTypes}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={colourStyles}
                    // options={allEquipmentTypes?.map((type) => {
                    //   return { value: type.id, label: type.name };
                    // })}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    placeholder="-Select-"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // onChange={(selectedOptions) =>
                    //   setEquipementTypes(selectedOptions)
                    // }
                  />
                </div>
                <div className="lanes-panel-form-1-div">
                  <label>Modes</label>
                  <Select
                    // value={modes}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={colourStyles}
                    // options={allModes?.map((type) => {
                    //   return { value: type.id, label: type.name };
                    // })}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    placeholder="-Select-"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // onChange={(selectedOptions) => setModes(selectedOptions)}
                  />
                </div>
                <div className="lanes-panel-form-1-div">
                  <label>Special Requirements</label>
                  <Select
                    // value={speciaRequirements}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={colourStyles}
                    // options={allSpecialRequirements?.map((type) => {
                    //   return { value: type.id, label: type.name };
                    // })}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    placeholder="-Select-"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // onChange={(selectedOptions) =>
                    //   setSpeciaRequirements(selectedOptions)
                    // }
                  />
                </div>
                <div className="lanes-panel-form-1-div">
                  <label>Lane Type</label>
                  <Select
                    // value={laneTypes}
                    closeMenuOnSelect={false}
                    isMulti
                    styles={colourStyles}
                    // options={allLaneTypes?.map((type) => {
                    //   return { value: type.id, label: type.name };
                    // })}
                    components={{
                      IndicatorSeparator: () => null,
                      ClearIndicator: () => null,
                      DropdownIndicator: () => null,
                    }}
                    placeholder="-Select-"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    // onChange={(selectedOptions) => setLaneTypes(selectedOptions)}
                  />
                </div>
              </div>
              <LaneCustomSort
                onSortEnd={this.onSortEnd}
                accountFields={this.state?.accountFields}
                handleEditOpenModal={this.handleEditOpenModal}
                handleDeleteOpenModal={this.handleDeleteOpenModal}
                handleGroupDeleteOpenModal={this.handleGroupDeleteOpenModal}
                handleGroupEditOpenModal={this.handleGroupEditOpenModal}
                handleOpenModal={this.handleOpenModal}
                deleteGroupHandler={this.deleteGroupHandler}
                onFieldSortEnd={this.onFieldSortEnd}
                defaultFields={this.state.defaultFields}
                groupId={groupId}
                group={group}
              />
              <div>
                <label>
                  Days of Week{" "}
                  <span style={{ fontSize: "12px" }}>
                    (What days the freight ships out for this lane)
                  </span>
                </label>
                <div
                  className="modal-radioButton c-radioButton"
                  style={{ marginTop: "5px" }}
                >
                  {days.map((day) => (
                    <span key={day}>
                      <label
                        htmlFor={`week_${day}`}
                        style={{
                          color: "#555555",
                          padding: "4px 12px",
                          border: "none",
                          borderRadius: "9px",
                          background: "#F4F6F5",
                          marginRight: "8px",
                          fontWeight: "normal",
                          fontSize: "14px",
                          lineHeight: "16px",
                          textTransform: "uppercase",
                        }}
                      >
                        {day}
                        <input
                          type="checkbox"
                          name="category"
                          id={`week_${day}`}
                          className="radio-button"
                          // checked={daysOfWeek.includes(day)}
                          // value={day}
                          // onChange={(e) => daysOfWeekChangeHandler(e)}
                        />
                      </label>
                    </span>
                  ))}
                </div>
              </div>
              <div
                className="lanes-panel-form-1-div"
                style={{ width: "100%", maxWidth: "100%", marginRight: "0" }}
              >
                <label>Notes</label>
                <textarea
                  // value={description}
                  // onChange={(e) => setDescription(e.target.value)}
                  style={{ width: "98.5%" }}
                ></textarea>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  marginBottom: "10px",
                }}
              >
                <button
                  className="button-md"
                  type="button"
                  onClick={() => this.handleOpenModal(groupId)}
                  // disabled={loading}
                  // onClick={createLane}
                >
                  Add Fields
                  <Plus
                    size={16}
                    color="#62CA9D"
                    style={{
                      transform: "translateY(2px)",
                      marginLeft: "5px",
                    }}
                  />
                </button>
              </div>
            </div>
          </div>
          <CustomFieldHelperText windowWidth={this.state.windowWidth} />
        </div>
        <FieldModal
          showOpportunityModal={this.state.showOpportunityModal}
          setShowOpportunityModal={this.handleOpenModal}
          handleCloseModal={this.handleCloseModal}
          fieldTypes={this.state?.fieldTypes}
          fieldLabel={this.state?.fieldLabel}
          fieldRequired={this.state.fieldRequired}
          fieldRequiredCheckBoxChangeHandler={
            this.fieldRequiredCheckBoxChangeHandler
          }
          addFieldHandler={this.addFieldHandler}
          changeHandler={this.changeHandler}
          fieldType={this.state.fieldType}
          accountFields={this.state.accountFields}
          accountField={this.state.accountField}
          fieldChoices={this.state.fieldChoices}
          choices={this.state.choices}
          addChoice={this.addChoice}
          hasChoices={this.state.hasChoices}
          changeNewChoiceHandler={this.changeNewChoiceHandler}
          addFieldModalGroupId={this.state.addFieldModalGroupId}
          deleteChoiceNewChangeHandler={this.deleteChoiceNewChangeHandler}
          showPreview={this.showPreview}
          errorMessage={this.state.errorMessage}
          isLoading={this.state.isLoading}
        />
        <GroupModal
          showOpportunityModal={this.state.showGroupModal}
          label={this.state?.label}
          setShowOpportunityModal={this.handleGroupOpenModal}
          handleCloseModal={this.handleGroupCloseModal}
          addGroupHandler={this.addGroupHandler}
          changeHandler={this.changeHandler}
          errorMessage={this.state.errorMessage}
        />
        <EditGroupModal
          showOpportunityModal={this.state.showGroupEditModal}
          label={this.state?.label}
          setShowOpportunityModal={this.handleGroupEditOpenModal}
          handleCloseModal={this.handleGroupEditCloseModal}
          editGroupHandler={this.editGroupHandler}
          groupLabelChangeHandler={this.groupLabelChangeHandler}
          currentGroup={this.state.currentGroup}
          errorMessage={this.state.errorMessage}
          editFieldLabel={this.state.editFieldLabel}
          isLoading={this.state.isLoading}
        />
        <EditFieldModal
          showOpportunityModal={this.state.showEditModal}
          setShowOpportunityModal={this.handleEditOpenModal}
          handleCloseModal={this.handleEditCloseModal}
          editRequiredFieldChangeHandler={this.editRequiredFieldChangeHandler}
          currentField={this.state.currentField}
          fieldTypes={this.state?.fieldTypes}
          fieldLabel={this.state?.fieldLabel}
          editFieldLabel={this.state?.editFieldLabel}
          editFieldType={this.state?.editFieldType}
          editFieldChoices={this.state?.editFieldChoices}
          editFieldHandler={this.editFieldHandler}
          changeHandler={this.changeHandler}
          fieldType={this.state.fieldType}
          accountFields={this.state.accountFields}
          accountField={this.state.accountField}
          choices={this.state.choices}
          addChoice={this.addChoice}
          hasChoices={this.state.hasChoices}
          editFieldChangeHandler={this.editFieldChangeHandler}
          editChoiceChangeHandler={this.editChoiceChangeHandler}
          deleteFieldHandler={this.deleteFieldHandler}
          addEditFieldChoice={this.addEditFieldChoice}
          deleteChoiceChangeHandler={this.deleteChoiceChangeHandler}
          changeEditFieldNewChoiceHandler={this.changeEditFieldNewChoiceHandler}
          deleteEditChoiceNewChangeHandler={
            this.deleteEditChoiceNewChangeHandler
          }
          isLoading={this.state.isLoading}
        />
        <DeleteFieldModal
          showOpportunityModal={this.state.showDeleteModal}
          setShowOpportunityModal={this.handleDeleteOpenModal}
          handleCloseModal={this.handleDeleteCloseModal}
          currentField={this.state.currentField}
          fieldTypes={this.state?.fieldTypes}
          fieldLabel={this.state?.fieldLabel}
          editFieldLabel={this.state?.editFieldLabel}
          editFieldType={this.state?.editFieldType}
          editFieldChoices={this.state?.editFieldChoices}
          editFieldHandler={this.editFieldHandler}
          changeHandler={this.changeHandler}
          fieldType={this.state.fieldType}
          accountFields={this.state.accountFields}
          accountField={this.state.accountField}
          choices={this.state.choices}
          addChoice={this.addChoice}
          hasChoices={this.state.hasChoices}
          editFieldChangeHandler={this.editFieldChangeHandler}
          editChoiceChangeHandler={this.editChoiceChangeHandler}
          deleteFieldHandler={this.deleteFieldHandler}
        />
        <DeleteGroupModal
          showOpportunityModal={this.state.showGroupDeleteModal}
          setShowOpportunityModal={this.handleGroupDeleteOpenModal}
          handleCloseModal={this.handleGroupDeleteCloseModal}
          currentGroup={this.state.currentGroup}
          deleteGroupHandler={this.deleteGroupHandler}
        />
        <StatusChangeModal
          showOpportunityModal={this.state.showStatusModal}
          setShowOpportunityModal={this.handleOpenStatusModal}
          handleCloseModal={this.handleCloseStatusModal}
          statusType="laneStatus"
        />
        {this.state.showYoutubeVideo && (
          <YoutubePlayer
            src="https://www.youtube.com/embed/WKerWCgCzZA"
            closeIframe={() => this.setState({ showYoutubeVideo: false })}
          />
        )}
      </div>
    );
  }
}

const MSP = (state) => {
  return {
    companyOnboarding: state.login.companyOnboarding,
    allLaneCustomFields: state.allLaneCustomFields.fields,
  };
};

const MDP = (dispatch) => {
  return {
    companyOnboardingSet: (companyOnboardingData) =>
      dispatch(companyOnboardingSet(companyOnboardingData)),
    fetchAllLaneCustomFields: (data) =>
      dispatch(fetchAllLaneCustomFields(data)),
  };
};
export default connect(MSP, MDP)(LaneCustom);
