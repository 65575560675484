export const laneColumns = [
  {
    name: "Account Name",
    key: "account_name",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: false,
  },
  {
    name: "Carrier Name",
    key: "carrier_name",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: false,
  },
  {
    name: "Account Owners",
    key: "account_owners",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: false,
  },
  {
    name: "Carrier Owners",
    key: "carrier_owners",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: false,
  },
  {
    name: "Origin",
    key: "origin_address",
    selected: true,
    custom: false,
    type: "address",
    choices: [],
    editable: true,
  },
  {
    name: "Destination",
    key: "destination_address",
    selected: true,
    custom: false,
    type: "address",
    choices: [],
    editable: true,
  },
  {
    name: "Description",
    key: "description",
    selected: true,
    custom: false,
    type: "text",
    choices: [],
    editable: true,
  },
  {
    name: "Linehaul",
    key: "price",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
  {
    name: "Rate per Mile",
    key: "rate_per_mile",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
  {
    name: "Equipment Types",
    key: "equipment_types",
    selected: true,
    custom: false,
    type: "multi_select_dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Special Requirements",
    key: "requirement_types",
    selected: false,
    custom: false,
    type: "multi_select_dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Modes",
    key: "modes",
    selected: false,
    custom: false,
    type: "multi_select_dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Lane Types",
    key: "types",
    selected: false,
    custom: false,
    type: "multi_select_dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Days of Week",
    key: "days_of_week",
    selected: false,
    custom: false,
    type: "multi_select_dropdown",
    choices: [],
    editable: true,
  },
  {
    name: "Created Date",
    key: "created_date",
    selected: true,
    custom: false,
    type: "date",
    choices: [],
    editable: false,
  },
  // {
  //   name: "Status",
  //   key: "status",
  //   selected: false,
  //   custom: false,
  //   type: "dropdown",
  //   choices: [],
  //   editable: true,
  // },
  {
    name: "Last Edit Date",
    key: "last_updated",
    selected: false,
    custom: false,
    type: "date",
    choices: [],
    editable: false,
  },
  {
    name: "Volume",
    key: "volume",
    selected: true,
    custom: false,
    type: "decimal",
    choices: [],
    editable: true,
  },
];
