import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { lanesStatusColor } from "../../constants/lanes/lanesStatus";
import DashboardLoader from "../Loaders/DashboardLoader";
import "./LanesView.scss";

import { Box, Typography, Paper, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

// Styled components

const QuoteCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  marginBottom: "16px",
  borderRadius: 8,
  border: "1px solid #E0E0E0",
  boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.3)",

  width: "95%",
  transition: "transform 0.2s ease, box-shadow 0.2s ease",
  "&:hover": {
    transform: "translateY(-1px)",
    boxShadow: "0px 2px 6px rgba(200, 200, 200, 0.5)",
    backgroundColor: "white !important",
  },
}));

const FilterButton = ({ type, label, isActive, onToggle, color }) => {
  return (
    <button
      onClick={onToggle}
      className={`filter-button ${type} ${isActive ? "active" : ""}`}
      style={
        isActive
          ? {
              color,
              backgroundColor: lanesStatusColor?.[color] || "#FFF3EE",
              borderColor: `${color}33`,
            }
          : {}
      }
    >
      {label}
      {/* {activeCount && <span className="count">({activeCount})</span>} */}
    </button>
  );
};

const LanesView = ({
  allLaneStatuses,
  allLanes,
  selectedLaneStatusIds,
  toggleLaneStatusFilter,
  getAccountLanes,
  hasMoreLanes,
  setAddLane,
  addLane,
  setLaneData,
  setLaneMode,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const truncateText = (text, maxLength = 20) => {
    if (!text) return "";
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };
  return (
    <div className="lanes-view">
      {/* <div className="filters-section">
        <div
          className="filters-label"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Filter by status
          <i
            style={{ marginLeft: "5px" }}
            className={`fa fa-chevron-${isExpanded ? "up" : "down"}`}
            aria-hidden="true"
          ></i>
        </div>
        {isExpanded && (
          <div className="filters-container">
            {allLaneStatuses?.map((status) => (
              <FilterButton
                type="prospect"
                label={status.name}
                isActive={selectedLaneStatusIds.includes(status.id)}
                onToggle={() => toggleLaneStatusFilter(status.id)}
                color={status.color}
                key={status.id + status.name + uuidv4()}
              />
            ))}
          </div>
        )}
      </div> */}

      <div className="lanes-container">
        <div
          id="scrollableDiv"
          style={{ maxHeight: "700px", overflow: "auto", height: "auto" }}
        >
          <InfiniteScroll
            pageStart={1}
            loadMore={(page) => getAccountLanes(page, "nextPage")}
            hasMore={hasMoreLanes}
            loader={<DashboardLoader key={0} />}
            useWindow={false}
            initialLoad={false}
            getScrollParent={() => document.getElementById("scrollableDiv")}
          >
            {allLanes?.map((lane) => (
              <QuoteCard
                elevation={0}
                className="lane-item"
                key={lane.id + uuidv4()}
                onClick={() => {
                  setAddLane(true);
                  setLaneData(lane);
                  setLaneMode("edit");
                }}
              >
                <Box
                  sx={{
                    mb: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Chip
                      label={lane?.status}
                      size="small"
                      sx={{
                        backgroundColor:
                          lanesStatusColor?.[lane?.status_color] || "#FFF3EE",
                        borderColor: `${lane?.status_color}33`,
                        color: lane?.status_color,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        height: "24px",
                        borderRadius: "16px",
                        fontSize: "13px",
                      }}
                    />

                    <Typography
                      variant="body2"
                      component="span"
                      sx={{ ml: 1, color: "#555", fontSize: "12px" }}
                    >
                      {moment(lane.created_at).format("L")}
                    </Typography>

                    {lane?.account_owners?.map((item) => (
                      <span>
                        <PersonOutlineIcon
                          fontSize="small"
                          sx={{
                            ml: 1,
                            verticalAlign: "middle",
                            color: "#777",
                            fontSize: "14px",
                          }}
                        />
                        <Typography
                          variant="body2"
                          component="span"
                          title={item?.name}
                          sx={{ ml: 0.5, color: "#555", fontSize: "12px" }}
                        >
                          {truncateText(item?.name)}
                        </Typography>
                      </span>
                    ))}
                  </div>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, mr: 1, fontSize: "14px" }}
                    >
                      ${new Intl.NumberFormat("en-US").format(lane?.price)}{" "}
                      <span className="dot">•</span>{" "}
                      {new Intl.NumberFormat("en-US").format(
                        Number(lane?.rate_per_mile)
                      )}
                      <small>/mi</small>
                    </Typography>
                  </Box>
                </Box>

                {/* Route and price row */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", fontSize: "16px" }}
                  >
                    {lane?.origin_address
                      ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                      .trim()}{" "}
                    <ArrowForwardIcon
                      sx={{
                        fontSize: 15,
                        mx: 0.5,
                        position: "relative",
                        top: "2px",
                      }}
                    />{" "}
                    {lane?.destination_address
                      ?.replace(/\s*,?\s*(US|USA|United States)\s*$/i, "")
                      .trim()}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 600, mr: 1, fontSize: "14px" }}
                    >
                      ${new Intl.NumberFormat("en-US").format(lane?.volume)}
                      <small>/wk</small>
                    </Typography>
                  </Box>
                </Box>

                {/* Equipment type chips */}
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {lane?.equipment_types?.map((type, index) => (
                    <Chip
                      key={index}
                      label={type?.name}
                      size="small"
                      sx={{
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "16px",
                        height: "26px",
                        fontSize: "12px",
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                  ))}
                  {lane?.modes?.map((type, index) => (
                    <Chip
                      key={index}
                      label={type?.name}
                      size="small"
                      sx={{
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "16px",
                        height: "26px",
                        fontSize: "12px",
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                  ))}
                  {lane?.requirement_types?.map((type, index) => (
                    <Chip
                      key={index}
                      label={type?.name}
                      size="small"
                      sx={{
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "16px",
                        height: "26px",
                        fontSize: "12px",
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                  ))}
                  {lane?.types?.map((type, index) => (
                    <Chip
                      key={index}
                      label={type?.name}
                      size="small"
                      sx={{
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "16px",
                        height: "26px",
                        fontSize: "12px",
                        fontWeight: 500,
                        mr: 0.5,
                      }}
                    />
                  ))}
                </Box>
              </QuoteCard>
            ))}
          </InfiniteScroll>
        </div>

        <button
          className="add-lane-button"
          onClick={() => {
            setAddLane(!addLane);
            setLaneMode("create");
          }}
        >
          + Add Lane
        </button>
      </div>
    </div>
  );
};

export default React.memo(LanesView);
